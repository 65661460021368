import { graphql, useStaticQuery } from "gatsby";

const useServicesData = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ALL_SERVICES {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "services-tbwa" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                services
                logo {
                  publicURL
                }
                image {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges;
};

export default useServicesData;
