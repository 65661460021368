import React from "react";
import Helmet from "react-helmet";
import useServicesData from "../../components/SiteServices";
import Layout from "../../components/Layout";
import {
  HeroSection,
  UnderLine,
  Container
} from "../../components/global-style";
import servicesHero from "../../img/images/crea.jpg";
import styled from "styled-components";
import { Link } from "gatsby";
import BackSlash from "../../img/svg/backslash-yellow.svg";
const ServicesGrid = styled.div`
  a {
    color: black;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    img {
      width: 40px;
      opacity: 0;
    }
    &:hover {
      color: var(--tbwa-yellow);
      img {
        opacity: 1;
      }
    }
  }

  @media (max-width: 800px) {
    h2 {
      font-size: 1rem;
    }
  }
`;
const IframeContainer = styled.div`
  height: 50vh;
  width: 100%;
  position: relative;
  iframe {
    position: absolute;
    border: unset;
    box-shadow: 1px 1px 5px var(--tbwa-overlay);
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
`;
const Index = () => {
  console.log('useservices',useServicesData());
  const servicesData = useServicesData();

  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>Services-TBWA \ DJAZ</title>
        <meta
          name="description"
          content="Stratégie, Brand leadership et Digital Marketing. RP, Com’ Corporate et Influence, E-réputation, Veille Stratégique.
        Marketing Expérientiel."
        />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="TBWA\ DJAZ" />
        <meta property="og:url" content="/http://tbwadjaz.com" />
      </Helmet>
      <HeroSection bgImage={servicesHero}>
        <div style={{ padding: "4rem 0" }}>
          <Container>
            <h1>Services</h1>
            <UnderLine />
          </Container>
        </div>
      </HeroSection>
      <Container>
        <IframeContainer>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/dLLatgJyGLE"
          />
        </IframeContainer>
        <ServicesGrid>
          {servicesData?.map(data => {
            return (
              <div key={data.node.id} className="box-container">
                <Link to={data.node.fields.slug}>
                  <img src={BackSlash} />
                  <h2>{data.node.frontmatter.title}</h2>
                </Link>
              </div>
            );
          })}
        </ServicesGrid>
      </Container>
    </Layout>
  );
};

export default Index;
